
import Vue from 'vue';
import globalAws from '@/mixins/globalAws';

export default Vue.extend({
	name: 'PageNotFound',

	fetch({ error }) {
    	error({ statusCode: 404, message: 'Page not found' })
  	},

	data() {
		return {
			awsPath: globalAws,
		}
	},

	head() {
		return {
			title: 'Ups'
		};
	}
});
